import { trackDOMEvent } from '~/components/shared/tracking/segmentAnalytics';
import {
  ONE_MINUTE,
  ONE_HOUR,
  ONE_DAY,
  combineDateTime,
  addTime,
  getDatestring,
  formatTime,
} from '../../shared/dateUtilities';


// Get a time 15 minutes in the future
const getNextTime = (date) => new Date(date.getTime() + 15 * ONE_MINUTE);

const getFirstPossibleTime = (startTime = new Date()) => {
  // round to the nearest 15 minute interval
  let firstPossibleTime =
    Math.round(startTime.getTime() / (15 * ONE_MINUTE)) * 15 * ONE_MINUTE;
  if (firstPossibleTime - startTime.getTime() < 30 * ONE_MINUTE) {
    firstPossibleTime = new Date(firstPossibleTime + 15 * ONE_MINUTE);
  }
  return firstPossibleTime;
};


document.querySelectorAll('[data-booking-times-controller]').forEach(controllerEl => {
  // Find cards that need fake times and do those
  controllerEl.querySelectorAll('[data-booking-times-fake-card]').forEach(card => {
    const dataset = card.dataset;
    const max_times = parseInt(dataset.numberOfBookingTimes) || 4;
    if (typeof dataset.alwaysOpen !== "undefined") {
      const href = dataset.bookableTimesHref;
      const buttonsWrapperEl = card.querySelector(
        "[data-bookable-buttons-wrapper]"
      );
      const firstPossibleTime = getFirstPossibleTime();
      const seeMoreText = dataset.seeMoreText || "See more";
      const times = [];

      times.push(firstPossibleTime);

      let nextTime = getNextTime(firstPossibleTime);
      while (times.length < max_times) {
        times.push(nextTime);
        nextTime = getNextTime(nextTime);
      }

      const segmentEvent = dataset.bookableEvent;
      const segmentAction = "click bookable time";

      const createButtonLink = (
        time,
        href
      ) => `<a data-segment-event="${segmentEvent}"
                                               data-segment-action="${segmentAction}"
                                               class="primary-button sm"
                                               target=${setTarget(href)}
                                               href="${href}">${formatTime(
        time
      )}</a>`;

      const createSeeMoreLink = (href) =>
        `<a data-segment-event="${segmentEvent}" data-segment-action="click see more times" class="see-more-times" href="${href}">${seeMoreText}</a>`;

      const buttons = [
        ...times.map((time) => createButtonLink(time, href)),
        createSeeMoreLink(href),
      ];

      buttonsWrapperEl.innerHTML = buttons.join("");
      const listingProperties = getElementProperties(card);
      buttonsWrapperEl.querySelectorAll("a").forEach((el) => {
        el.addEventListener("click", (event) =>
          trackDOMEvent(event, listingProperties)
        );
      });
    } else {
      const hasHours = (hoursString) => {
        const noHoursStrings = ["Unknown", "Closed"];
        for (const noString of noHoursStrings) {
          if (hoursString.includes(noString)) {
            return false;
          }
        }
        return true;
      };

      const parseHoursString = (hoursString) => {
        const dayName = hoursString.substr(0, 3);

        const [startTime, endTime] = hoursString
          .substr(3)
          .split("-")
          .map((s) => s.trim());
        return [dayName, startTime, endTime];
      };

      // check the time isn't too close to closing
      const isTimeValid = (time, closeTime) => closeTime - time >= 15 * ONE_MINUTE;

      let hoursList;
      let todaysHours;

      if (dataset.fakeHours === 'true') {
        hoursList = [
          "Mon 9:00 am -  5:00 pm",
          "Tue 9:00 am -  5:00 pm",
          "Wed 9:00 am -  5:00 pm",
          "Thu 9:00 am -  5:00 pm",
          "Fri 9:00 am -  5:00 pm",
          "Sat 9:00 am -  5:00 pm",
          "Sun 9:00 am -  5:00 pm",
        ];
        const todayDayName = new Date().toLocaleDateString("en-US", {
          weekday: "short",
        });
        todaysHours = hoursList.find((hoursString) =>
          hoursString.includes(todayDayName)
        );
      } else {
        hoursList = Array.from(
          card.querySelector("[data-hours-list]").children
        ).map((el) => el.innerText);

        todaysHours = card.querySelector(
          ".unified_listing-card__current-hours"
        )?.dataset.todaysHours;

      }

      const getNextOpenHours = () => {
        const todayDayName = new Date().toLocaleDateString("en-US", {
          weekday: "short",
        });
        const todayIndex = hoursList.findIndex((hoursString) =>
          hoursString.includes(todayDayName)
        );

        let nextDayIndex = todayIndex + 1;
        let nextDayHours = hoursList[nextDayIndex % hoursList.length];

        while (!hasHours(nextDayHours)) {
          nextDayIndex += 1;
          nextDayHours = hoursList[nextDayIndex % hoursList.length];
        }

        const [_day, startTime, endTime] = parseHoursString(nextDayHours);
        const nextDayDate = addTime((nextDayIndex - todayIndex) * ONE_DAY);
        const openingTime = combineDateTime(nextDayDate, startTime);
        const closingTime = combineDateTime(nextDayDate, endTime);
        return [openingTime, closingTime];
      };

      // Get the first valid opening/closing times
      const getOpeningAndClosing = (currentTime) => {
        if (todaysHours && hasHours(todaysHours)) {
          const [_day, startTime, endTime] = parseHoursString(todaysHours);
          const openingTime = combineDateTime(new Date(), startTime);
          const closingTime = combineDateTime(new Date(), endTime);
          // need to check if we're too close to closing time, if so we need to just move on and get the next days opening time
          if (isTimeValid(getFirstPossibleTime(currentTime), closingTime)) {
            return [openingTime, closingTime];
          }
        }
        return getNextOpenHours();
      };

      // Get title text for availability
      const getTitle = (time, times_name = "times") => {
        const currentDatestring = getDatestring();
        const firstTimeDatestring = getDatestring(time);
        const tomorrowDatestring = getDatestring(addTime(ONE_DAY));

        if (currentDatestring === firstTimeDatestring) {
          return `Available ${times_name} today`;
        }
        if (currentDatestring === tomorrowDatestring) {
          return `Available ${times_name} tomorrow`;
        }

        const dayName = time.toLocaleDateString("en-US", { weekday: "long" });
        return `Available ${times_name} on ${dayName}`;
      };

      const getSegmentActionDay = (time) =>
        getTitle(time).includes("today") ? "today" : "tomorrow";

      const getSegmentAction = (time) => {
        return `click bookable time - ${getSegmentActionDay(time)}`;
      };

      const getSegmentProperties = () => {
        let props = "";
        if (card.dataset.segmentCardRank) {
          props += `data-segment-card-rank="${card.dataset.segmentCardRank}" `;
        }
        if (card.dataset.segmentLocationId) {
          props += `data-segment-location-id="${card.dataset.segmentLocationId}"`;
        }
        return props;
      };

      const setTarget = (href) => {
        if (href === 'javascript:void(0);') {
          return '';
        }
        return '_blank';
      }

      const createButtonLink = (time, href, event = "Directory - CDP Card") => {

        return `<a data-segment-event="${event}"
                   data-segment-action="${getSegmentAction(time)}"
                   ${getSegmentProperties()}
                   class="light-button light-button--sm"
                   target=${setTarget(href)}
                   href="${href}">${formatTime(time)}</a>`;

      }
      const renderNoTimes = () => {
        const timesContainer = card.querySelector(
          ".unified-listing-card__bookable-times"
        );

        const title = "No available times today or tomorrow";

        const titleEl = `<h4 class="bookable-times__title">${title}</h4>`;
        const buttonsWrapper = `<div class="bookable-times__buttons-wrapper"><button class="primary-button sm disabled" disabled>Closed</button></div>`;
        timesContainer.innerHTML = titleEl + buttonsWrapper;
      };

      // first check if the location has no hours
      if (typeof card.dataset.hasNoHours !== "undefined") {
        renderNoTimes();
      } else {
        // Handle timezone offsets
        let timezoneOffset = 0;
        if (card.dataset.timezoneOffset) {
          timezoneOffset = parseInt(card.dataset.timezoneOffset);
        }

        const localOffset = new Date().getTimezoneOffset() / 60;

        // this is the offset we need to add to local time to compare to hours list times in ms
        const timeOffset = (timezoneOffset + localOffset) * ONE_HOUR;

        // first we will make an array of times to show
        const times = [];
        const currentTime = new Date(new Date().getTime() + timeOffset);
        const [openTime, closeTime] = getOpeningAndClosing(currentTime);
        let firstPossibleTime;
        // if open time is in the future, use the open time for first possible
        if (currentTime < openTime) {
          firstPossibleTime = getFirstPossibleTime(openTime);
        } else {
          firstPossibleTime = getFirstPossibleTime(currentTime);
        }

        // check if firstPossibleTime is more than 2 days away
        const dayAfterTomorrow = getDatestring(addTime(2 * ONE_DAY));
        if (dayAfterTomorrow <= getDatestring(firstPossibleTime) && card.dataset.segmentGroupId !== "LeOBVQ") {
          return renderNoTimes();
        }

        times.push(firstPossibleTime);

        let nextTime = getNextTime(firstPossibleTime);

        while (isTimeValid(nextTime, closeTime) && times.length < max_times) {
          times.push(nextTime);
          nextTime = getNextTime(nextTime);
        }

        const href = card.dataset.permalink;

        let segmentEvent = "Directory - CDP Card";

        const isInPersonAd = card.classList.contains("solv-plus-in-person-listing");

        if (isInPersonAd) {
          segmentEvent = "Directory - CTA";
        } else if (card.dataset.segmentEvent) {
          segmentEvent = card.dataset.segmentEvent;
        }

        const buttons = [
          ...times
            .map((time) => createButtonLink(time, href, segmentEvent)),
          `<a data-segment-event="${segmentEvent}" ${getSegmentProperties()} data-segment-action="click see more times - ${getSegmentActionDay(
            times[0]
          )}" class="see-more-times" target=${setTarget(href)} href="${href}">See more <br>times</a>`,
        ];

        const timesContainer = card.querySelector(
          ".unified-listing-card__bookable-times"
        );

        const times_name = isInPersonAd ? "in-person visit times" : "times";
        const title = getTitle(times[0], times_name);

        const titleEl = `<h4 class="bookable-times__title">${title}</h4>`;
        const buttonsWrapper = `<div class="bookable-times__buttons-wrapper">${buttons.join(
          ""
        )}</div>`;
        timesContainer.innerHTML = titleEl + buttonsWrapper;

        timesContainer.querySelectorAll("a").forEach((el) => {
          el.addEventListener("click", (event) => trackDOMEvent(event));
        });
      }
    }
  });
});


